import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography, FormControlLabel, Switch, FormControl, FormLabel, RadioGroup, Radio, } from '@material-ui/core';
import { Chat } from '@material-ui/icons';
import { NotesTab } from './NotesTab';
import styles from '../LayerModal/LayerModal.module.scss';
import constructionStyles from './ConstructionModal.module.scss';
import { FloorInsulationLayerReferenceType, } from '../../types/store/calculationTypes';
import { applicationTypeSelector, interimOrCurrentCalculationSelector, soilTypeSelector, floorMasterLayerSelector, drainageFxSelector, precipitationByPostCodeAreaSelector, rainscreenCladdingDefaultsSelector, roofCharacteristicsOptionsSelector, productSectorSelector, } from '../../store/selectors';
import { TabPanel } from '../LayerModal/TabPanel';
import { VerticalAlign } from '../Utilities/VerticalAlign/VerticalAlign';
import { addConstructionDetailsToCalculation } from '../../actions/calculationActions';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { isStringValidPostiveNumber, isStringNotNullishOrWhitespace } from '../../common/inputValidation';
import { noFloorReferenceOption, clientSpecifiedFloorReferenceOption, } from './ConstructionModalStateTypes';
import { uuid } from '../../common/uuid';
const config = {
    stringify: (option) => option.name,
};
const filter = createFilterOptions(config);
const wallType = 'Wall';
const floorOtherThanGroundType = 'Floor';
const solidGroundFloorType = 'SolidGroundFloor';
const suspendedFloorType = 'SuspendedFloor';
const basementWallType = 'BasementWall';
const basementFloorType = 'BasementFloor';
const steelFrameWallType = 'SteelFrameWall';
const invertedRoofType = 'InvertedRoof';
const pitchedRoofWithRafterInsulation = 'PitchedRoofWithRafterInsulation';
const pitchedRoofWithVentilatedLoftSpace = 'PitchedRoofWithVentilatedLoftSpace';
const floorWithPerimeterInsulation = 'FloorWithPerimeterInsulation';
const typesWithWindShielding = [suspendedFloorType];
const typesWithPaRatio = [solidGroundFloorType, suspendedFloorType, basementFloorType, floorWithPerimeterInsulation];
const typesWithWallThickness = [
    solidGroundFloorType,
    suspendedFloorType,
    basementFloorType,
    basementWallType,
    floorWithPerimeterInsulation,
];
const typesWithSoilType = [solidGroundFloorType, suspendedFloorType, basementFloorType, basementWallType, floorWithPerimeterInsulation];
const typesWithHeightBelowGround = [basementWallType, suspendedFloorType, basementFloorType];
const typesWithFloorInsulationDetails = [basementWallType, floorWithPerimeterInsulation];
const typesWithPitchAngle = [pitchedRoofWithRafterInsulation];
const typesWithInsulationWidth = [floorWithPerimeterInsulation];
const typesWithConstructionDetails = [
    steelFrameWallType,
    solidGroundFloorType,
    suspendedFloorType,
    basementWallType,
    basementFloorType,
    invertedRoofType,
    pitchedRoofWithRafterInsulation,
    pitchedRoofWithVentilatedLoftSpace,
    floorWithPerimeterInsulation,
];
const typesWithRainscreenCladding = [wallType, floorOtherThanGroundType, steelFrameWallType];
function applicationTypeHasHeightBelowGround(applicationType) {
    return applicationType != null && typesWithHeightBelowGround.includes(applicationType);
}
function applicationTypeHasPaRatio(applicationType) {
    return applicationType != null && typesWithPaRatio.includes(applicationType);
}
function applicationTypeHasWallThickness(applicationType) {
    return applicationType != null && typesWithWallThickness.includes(applicationType);
}
function applicationTypeHasSoilType(applicationType) {
    return applicationType != null && typesWithSoilType.includes(applicationType);
}
function applicationTypeHasWindShielding(applicationType) {
    return applicationType != null && typesWithWindShielding.includes(applicationType);
}
function applicationTypeHasPitchAngle(applicationType) {
    return applicationType != null && typesWithPitchAngle.includes(applicationType);
}
function applicationTypeIsSteelFrameWall(applicationType) {
    return applicationType != null && applicationType === steelFrameWallType;
}
function applicationTypeHasFloorInsulationDetails(applicationType) {
    return applicationType != null && typesWithFloorInsulationDetails.includes(applicationType);
}
function applicationTypeHasRainscreenCladding(applicationType) {
    return applicationType != null && typesWithRainscreenCladding.includes(applicationType);
}
function applicationTypeHasConstructionDetails(applicationType) {
    return applicationType != null && typesWithConstructionDetails.includes(applicationType);
}
function applicationTypeIsInvertedRoof(applicationType) {
    return applicationType != null && applicationType === invertedRoofType;
}
function applicationTypeHasConstructionDetailsResults(applicationType) {
    return applicationTypeHasSoilType(applicationType) || applicationTypeIsInvertedRoof(applicationType);
}
function applicationTypeIsPitchedRoofWithVentilatedLoftSpace(applicationType) {
    return applicationType != null && applicationType === pitchedRoofWithVentilatedLoftSpace;
}
function applicationTypeIsFloorWithPerimeterInsulation(applicationType) {
    return applicationType != null && applicationType === floorWithPerimeterInsulation;
}
function applicationTypeHasInsulationWidth(applicationType) {
    return applicationType != null && typesWithInsulationWidth.includes(applicationType);
}
function getInitialFloorMasterLayer(floorInsulationDetails, masterFloorLayers) {
    if (!masterFloorLayers || (floorInsulationDetails === null || floorInsulationDetails === void 0 ? void 0 : floorInsulationDetails.insulationReference.insulationReferenceType) == null) {
        return null;
    }
    switch (floorInsulationDetails === null || floorInsulationDetails === void 0 ? void 0 : floorInsulationDetails.insulationReference.insulationReferenceType) {
        case FloorInsulationLayerReferenceType.None:
            return noFloorReferenceOption;
        case FloorInsulationLayerReferenceType.Custom:
            return clientSpecifiedFloorReferenceOption;
        case FloorInsulationLayerReferenceType.Master:
            const masterFloorLayer = masterFloorLayers.find(layer => layer.id != null && layer.id === ((floorInsulationDetails === null || floorInsulationDetails === void 0 ? void 0 : floorInsulationDetails.insulationReference.id) || ''));
            if (masterFloorLayer == null) {
                return null;
            }
            return masterFloorLayer;
        default:
            return null;
    }
}
export function ConstructionModal(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43, _44, _45, _46, _47;
    const dispatch = useDispatch();
    const calculation = useSelector(interimOrCurrentCalculationSelector);
    const applicationTypes = useSelector(applicationTypeSelector);
    const soilTypes = useSelector(soilTypeSelector);
    const productSectors = useSelector(productSectorSelector);
    const drainageFxTypes = useSelector(drainageFxSelector);
    const precipitationByPostCodeAreas = useSelector(precipitationByPostCodeAreaSelector);
    const floorLayers = useSelector(floorMasterLayerSelector);
    const rainscreenCladdingDefaults = useSelector(rainscreenCladdingDefaultsSelector);
    const roofCharacteristicsOptions = useSelector(roofCharacteristicsOptionsSelector);
    const floorReferenceOptions = useMemo(() => [...floorLayers, clientSpecifiedFloorReferenceOption, noFloorReferenceOption], [floorLayers]);
    const initialSelectedApplicationType = (_a = applicationTypes.find(({ id }) => id === (calculation === null || calculation === void 0 ? void 0 : calculation.applicationDetails.id))) === null || _a === void 0 ? void 0 : _a.type;
    const initialSelectedApplicationName = (_b = applicationTypes.find(({ id }) => id === (calculation === null || calculation === void 0 ? void 0 : calculation.applicationDetails.id))) === null || _b === void 0 ? void 0 : _b.name;
    const initialDrainageFxTypeId = ((_d = (_c = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _c === void 0 ? void 0 : _c.invertedRoofDetails) === null || _d === void 0 ? void 0 : _d.drainageFxId)
        ? (_f = (_e = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _e === void 0 ? void 0 : _e.invertedRoofDetails) === null || _f === void 0 ? void 0 : _f.drainageFxId
        : 4;
    const initialTabsForSelection = [
        applicationTypeHasConstructionDetails(initialSelectedApplicationType) ? 0 : -1,
        applicationTypeHasRainscreenCladding(initialSelectedApplicationType) ? 1 : -1,
        2, // Fallback to Notes tab
    ];
    const [localState, setLocalState] = React.useState({
        selectedTab: initialTabsForSelection.filter(x => x !== -1)[0],
        applicationTypeId: (calculation === null || calculation === void 0 ? void 0 : calculation.applicationDetails.id) || 1,
        perimeterMetres: (_h = (_g = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _g === void 0 ? void 0 : _g.perimeterMetres) !== null && _h !== void 0 ? _h : '0.000',
        areaMetresSquared: (_k = (_j = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _j === void 0 ? void 0 : _j.areaMetresSquared) !== null && _k !== void 0 ? _k : '0.00',
        perimeterAreaRatio: (_m = (_l = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _l === void 0 ? void 0 : _l.perimeterAreaRatio) !== null && _m !== void 0 ? _m : '0.000',
        wallThicknessMetres: (_p = (_o = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _o === void 0 ? void 0 : _o.wallThicknessMetres) !== null && _p !== void 0 ? _p : '0.30',
        widthOfEdgeInsulation: (_r = (_q = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _q === void 0 ? void 0 : _q.widthOfEdgeInsulation) !== null && _r !== void 0 ? _r : '1200',
        ventilationRateMillimetresSquaredPerMetre: (_t = (_s = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _s === void 0 ? void 0 : _s.ventilationRateMillimetresSquaredPerMetre) !== null && _t !== void 0 ? _t : '1500',
        thermalTransmittanceWallsInUnderFloorSpaceAboveGround: (_v = (_u = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _u === void 0 ? void 0 : _u.thermalTransmittanceWallsInUnderFloorSpaceAboveGround) !== null && _v !== void 0 ? _v : '1.7',
        windSpeedMetresPerSecond: (_x = (_w = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _w === void 0 ? void 0 : _w.windSpeedMetresPerSecond) !== null && _x !== void 0 ? _x : '5.0',
        averageWindShieldingFactor: (_z = (_y = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _y === void 0 ? void 0 : _y.averageWindShieldingFactor) !== null && _z !== void 0 ? _z : '0.05',
        heightBelowGroundMetres: (_1 = (_0 = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _0 === void 0 ? void 0 : _0.heightBelowGroundMetres) !== null && _1 !== void 0 ? _1 : '0.225',
        soilTypeId: applicationTypeHasSoilType(initialSelectedApplicationType)
            ? (_3 = (_2 = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _2 === void 0 ? void 0 : _2.soilTypeId) !== null && _3 !== void 0 ? _3 : soilTypes[0].id
            : null,
        floorInsulationLayer: getInitialFloorMasterLayer((_4 = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _4 === void 0 ? void 0 : _4.floorInsulationDetails, floorReferenceOptions),
        floorInsulationReferenceType: (_7 = (_6 = (_5 = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _5 === void 0 ? void 0 : _5.floorInsulationDetails) === null || _6 === void 0 ? void 0 : _6.insulationReference.insulationReferenceType) !== null && _7 !== void 0 ? _7 : null,
        floorInsulationLayerThickness: (_10 = (_9 = (_8 = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _8 === void 0 ? void 0 : _8.floorInsulationDetails) === null || _9 === void 0 ? void 0 : _9.thicknessMillimetres) !== null && _10 !== void 0 ? _10 : null,
        floorInsulationThermalConductivity: (_13 = (_12 = (_11 = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _11 === void 0 ? void 0 : _11.floorInsulationDetails) === null || _12 === void 0 ? void 0 : _12.thermalConductivity) !== null && _13 !== void 0 ? _13 : null,
        floorInsulationLayerInstanceId: (_16 = (_15 = (_14 = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _14 === void 0 ? void 0 : _14.floorInsulationDetails) === null || _15 === void 0 ? void 0 : _15.insulationLayerInstanceId) !== null && _16 !== void 0 ? _16 : null,
        isWarmSteelFramedWall: (_19 = (_18 = (_17 = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _17 === void 0 ? void 0 : _17.steelWallDetails) === null || _18 === void 0 ? void 0 : _18.isWarmSteelFramedWall) !== null && _19 !== void 0 ? _19 : false,
        is50To80MillimetreFlange: (_22 = (_21 = (_20 = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _20 === void 0 ? void 0 : _20.steelWallDetails) === null || _21 === void 0 ? void 0 : _21.is50To80MillimetreFlange) !== null && _22 !== void 0 ? _22 : true,
        studDepthMillimetres: (_25 = (_24 = (_23 = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _23 === void 0 ? void 0 : _23.steelWallDetails) === null || _24 === void 0 ? void 0 : _24.studDepthMillimetres) !== null && _25 !== void 0 ? _25 : '150.0',
        studSpacingMillimetres: (_28 = (_27 = (_26 = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _26 === void 0 ? void 0 : _26.steelWallDetails) === null || _27 === void 0 ? void 0 : _27.studSpacingMillimetres) !== null && _28 !== void 0 ? _28 : '400.0',
        rainscreenCladdingDetailsPartial: (_29 = calculation === null || calculation === void 0 ? void 0 : calculation.rainscreenCladdingDetails) !== null && _29 !== void 0 ? _29 : undefined,
        drainageFx: drainageFxTypes.filter(d => d.id === initialDrainageFxTypeId)[0],
        precipitationForArea: (_30 = precipitationByPostCodeAreas.filter(d => { var _a, _b; return d.id === ((_b = (_a = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _a === void 0 ? void 0 : _a.invertedRoofDetails) === null || _b === void 0 ? void 0 : _b.precipitationForAreaId); })[0]) !== null && _30 !== void 0 ? _30 : null,
        pitchAngle: applicationTypeHasPitchAngle(initialSelectedApplicationType)
            ? (_33 = (_32 = (_31 = calculation === null || calculation === void 0 ? void 0 : calculation.constructionDetails) === null || _31 === void 0 ? void 0 : _31.pitchedRoofDetails) === null || _32 === void 0 ? void 0 : _32.pitchAngle) !== null && _33 !== void 0 ? _33 : '30'
            : null,
        calculationNotes: (_34 = calculation === null || calculation === void 0 ? void 0 : calculation.calculationNotes) !== null && _34 !== void 0 ? _34 : { calculationTitle: initialSelectedApplicationName || null, notes: null },
        pitchedRoofWithLoftDetails: applicationTypeIsPitchedRoofWithVentilatedLoftSpace(initialSelectedApplicationType)
            ? calculation.constructionDetails.pitchedRoofWithLoftDetails
            : undefined,
        productSectorId: (calculation === null || calculation === void 0 ? void 0 : calculation.productSectorId) ? calculation.productSectorId : productSectors[0].id,
        projectInsulationVolumeM2: (calculation === null || calculation === void 0 ? void 0 : calculation.projectInsulationVolumeM2) ? calculation.projectInsulationVolumeM2 : 0,
    });
    const { selectedTab, applicationTypeId, soilTypeId, perimeterMetres, areaMetresSquared, perimeterAreaRatio, wallThicknessMetres, widthOfEdgeInsulation, ventilationRateMillimetresSquaredPerMetre, thermalTransmittanceWallsInUnderFloorSpaceAboveGround, windSpeedMetresPerSecond, averageWindShieldingFactor, heightBelowGroundMetres, floorInsulationLayer, floorInsulationReferenceType, floorInsulationLayerThickness, floorInsulationThermalConductivity, floorInsulationLayerInstanceId, isWarmSteelFramedWall, is50To80MillimetreFlange, studDepthMillimetres, studSpacingMillimetres, rainscreenCladdingDetailsPartial, drainageFx, precipitationForArea, pitchAngle, calculationNotes, pitchedRoofWithLoftDetails, productSectorId, projectInsulationVolumeM2 } = localState;
    const selectedApplicationType = (_35 = applicationTypes.find(({ id }) => id === localState.applicationTypeId)) === null || _35 === void 0 ? void 0 : _35.type;
    const selectedApplicationName = (_36 = applicationTypes.find(({ id }) => id === localState.applicationTypeId)) === null || _36 === void 0 ? void 0 : _36.name;
    const groundThermalConductivity = (_37 = soilTypes.find(({ id }) => id === localState.soilTypeId)) === null || _37 === void 0 ? void 0 : _37.lambda;
    const groundVapourResistivity = (_38 = soilTypes.find(({ id }) => id === localState.soilTypeId)) === null || _38 === void 0 ? void 0 : _38.vapourResistivity;
    const rainscreenCladdingCorrectionFactor = calculation === null || calculation === void 0 ? void 0 : calculation.rainscreenCladdingCorrectionFactor;
    const handleApplicationTypeChange = (event) => {
        var _a, _b;
        const newSelectedApplicationId = Number(event.target.value);
        const newSelectedApplicationType = (_a = applicationTypes.find(({ id }) => id === newSelectedApplicationId)) === null || _a === void 0 ? void 0 : _a.type;
        const selectedApplicationName = (_b = applicationTypes.find(({ id }) => id === newSelectedApplicationId)) === null || _b === void 0 ? void 0 : _b.name;
        setLocalState(prevState => {
            const tabsForSelection = [
                applicationTypeHasConstructionDetails(newSelectedApplicationType) ? 0 : -1,
                applicationTypeHasRainscreenCladding(newSelectedApplicationType) ? 1 : -1,
                2, // Fallback to Notes tab
            ];
            // Object to resetModalState. Only include values that should be changed on application type change
            const defaultsToResetTo = prevState.applicationTypeId !== newSelectedApplicationId
                ? {
                    selectedTab: tabsForSelection.filter(x => x !== -1)[0],
                    rainscreenCladdingDetailsPartial: undefined,
                    soilTypeId: applicationTypeHasSoilType(newSelectedApplicationType) ? soilTypes[0].id : null,
                    pitchAngle: applicationTypeHasPitchAngle(newSelectedApplicationType) ? '30' : null,
                    calculationNotes: Object.assign(Object.assign({}, prevState.calculationNotes), { calculationTitle: selectedApplicationName || '' }),
                    pitchedRoofWithLoftDetails: applicationTypeIsPitchedRoofWithVentilatedLoftSpace(newSelectedApplicationType)
                        ? { loftLayerInstanceId: uuid(), roofCharacteristicId: roofCharacteristicsOptions[0].id, thicknessMillimetres: '300.0' }
                        : undefined,
                    floorInsulationLayerInstanceId: applicationTypeIsFloorWithPerimeterInsulation(newSelectedApplicationType) ? uuid() : undefined
                }
                : {};
            return Object.assign(Object.assign(Object.assign({}, prevState), { applicationTypeId: newSelectedApplicationId }), defaultsToResetTo);
        });
    };
    const handleProjectInsulationVolumeM2Changed = (event) => {
        const newProjectInsulationVolumeM2 = Number(event.target.value);
        setLocalState(prevState => {
            return Object.assign(Object.assign({}, prevState), { projectInsulationVolumeM2: newProjectInsulationVolumeM2 });
        });
    };
    const handleProductSectorChange = (event) => {
        const newSelectedProductSectorId = Number(event.target.value);
        setLocalState(prevState => {
            const defaultsToResetTo = prevState.productSectorId !== newSelectedProductSectorId
                ? {
                    productSectorId: newSelectedProductSectorId
                }
                : {};
            return Object.assign(Object.assign(Object.assign({}, prevState), { productSectorId: newSelectedProductSectorId }), defaultsToResetTo);
        });
    };
    const handleSubmit = React.useCallback((event) => {
        event.preventDefault();
        const hasConstructionDetails = applicationTypeHasConstructionDetails(selectedApplicationType);
        const hasPaRatio = applicationTypeHasPaRatio(selectedApplicationType);
        const hasWindshielding = applicationTypeHasWindShielding(selectedApplicationType);
        const isSteelFramedWall = applicationTypeIsSteelFrameWall(selectedApplicationType);
        const hasWallThickness = applicationTypeHasWallThickness(selectedApplicationType);
        const hasInsulationWidth = applicationTypeHasInsulationWidth(selectedApplicationType);
        const hasHeightBelowGround = applicationTypeHasHeightBelowGround(selectedApplicationType);
        const hasSoilType = applicationTypeHasSoilType(selectedApplicationType);
        const hasPitchAngle = applicationTypeHasPitchAngle(selectedApplicationType);
        const isInvertedRoof = applicationTypeIsInvertedRoof(selectedApplicationType);
        const hasFloorInsulationDetails = applicationTypeHasFloorInsulationDetails(selectedApplicationType);
        const hasRainscreenCladdingDetails = applicationTypeHasRainscreenCladding(selectedApplicationType);
        const isPitchedRoofWithVentilatedLoftSpace = applicationTypeIsPitchedRoofWithVentilatedLoftSpace(selectedApplicationType);
        const floorInsulationDetails = hasFloorInsulationDetails && floorInsulationReferenceType != null
            ? {
                insulationReference: {
                    insulationReferenceType: floorInsulationReferenceType,
                    id: (floorInsulationLayer === null || floorInsulationLayer === void 0 ? void 0 : floorInsulationLayer.id) || null,
                },
                thicknessMillimetres: floorInsulationLayerThickness,
                thermalConductivity: floorInsulationThermalConductivity,
                insulationLayerInstanceId: floorInsulationLayerInstanceId
            }
            : undefined;
        const isRainscreenCladdingComplete = hasRainscreenCladdingDetails &&
            rainscreenCladdingDetailsPartial != null &&
            Number(rainscreenCladdingDetailsPartial === null || rainscreenCladdingDetailsPartial === void 0 ? void 0 : rainscreenCladdingDetailsPartial.chiValue) > 0 &&
            Number(rainscreenCladdingDetailsPartial === null || rainscreenCladdingDetailsPartial === void 0 ? void 0 : rainscreenCladdingDetailsPartial.bracketsPerMetreSquared) > 0;
        const rainscreenCladdingDetails = isRainscreenCladdingComplete
            ? {
                chiValue: rainscreenCladdingDetailsPartial.chiValue,
                bracketsPerMetreSquared: rainscreenCladdingDetailsPartial.bracketsPerMetreSquared,
            }
            : undefined;
        let steelWallDetails = null;
        if (isSteelFramedWall && isWarmSteelFramedWall != null) {
            steelWallDetails = {
                isWarmSteelFramedWall,
                is50To80MillimetreFlange: isSteelFramedWall ? is50To80MillimetreFlange : null,
                studDepthMillimetres: isSteelFramedWall ? studDepthMillimetres : null,
                studSpacingMillimetres: isSteelFramedWall ? studSpacingMillimetres : null,
            };
        }
        const invertedRoofDetails = isInvertedRoof
            ? {
                drainageFxId: drainageFx ? drainageFx.id : null,
                drainageFx: drainageFx ? drainageFx.fxFactor : null,
                precipitationForAreaId: precipitationForArea ? precipitationForArea === null || precipitationForArea === void 0 ? void 0 : precipitationForArea.id : null,
                precipitationForAreaMillimetres: precipitationForArea ? precipitationForArea === null || precipitationForArea === void 0 ? void 0 : precipitationForArea.rainfallMillimetres : null,
            }
            : null;
        const pitchedRoofDetails = hasPitchAngle && pitchAngle != null && Number(pitchAngle) >= 0 && Number(pitchAngle) <= 90
            ? {
                pitchAngle,
            }
            : undefined;
        const pitchedRoofWithLoftDetailsFinal = isPitchedRoofWithVentilatedLoftSpace
            ? pitchedRoofWithLoftDetails
            : undefined;
        const constructionDetails = hasConstructionDetails
            ? {
                soilTypeId: hasSoilType ? soilTypeId : null,
                perimeterMetres: hasPaRatio ? perimeterMetres : null,
                areaMetresSquared: hasPaRatio ? areaMetresSquared : null,
                perimeterAreaRatio: hasPaRatio ? perimeterAreaRatio : null,
                wallThicknessMetres: hasWallThickness ? wallThicknessMetres : null,
                widthOfEdgeInsulation: hasInsulationWidth ? widthOfEdgeInsulation : null,
                ventilationRateMillimetresSquaredPerMetre: hasWindshielding ? ventilationRateMillimetresSquaredPerMetre : null,
                thermalTransmittanceWallsInUnderFloorSpaceAboveGround: hasWindshielding
                    ? thermalTransmittanceWallsInUnderFloorSpaceAboveGround
                    : null,
                windSpeedMetresPerSecond: hasWindshielding ? windSpeedMetresPerSecond : null,
                averageWindShieldingFactor: hasWindshielding ? averageWindShieldingFactor : null,
                heightBelowGroundMetres: hasHeightBelowGround ? heightBelowGroundMetres : null,
                floorInsulationDetails: floorInsulationDetails,
                steelWallDetails,
                invertedRoofDetails,
                pitchedRoofDetails,
                pitchedRoofWithLoftDetails: pitchedRoofWithLoftDetailsFinal,
            }
            : undefined;
        // If null is somehow sent because we don't have an applicationName, the server will replace the title with the applicationName
        const titleFallback = isStringNotNullishOrWhitespace(selectedApplicationName) ? selectedApplicationName : null;
        const finalCalculationNotes = Object.assign(Object.assign({}, calculationNotes), { calculationTitle: isStringNotNullishOrWhitespace(calculationNotes.calculationTitle)
                ? calculationNotes.calculationTitle
                : titleFallback });
        // const projectInsulationVolumeM2: number = Number(localState.projectInsulationVolumeM2);
        dispatch(addConstructionDetailsToCalculation(applicationTypeId, constructionDetails, rainscreenCladdingDetails, finalCalculationNotes, productSectorId, projectInsulationVolumeM2));
        props.onClose();
    }, [
        dispatch,
        applicationTypeId,
        soilTypeId,
        perimeterMetres,
        areaMetresSquared,
        perimeterAreaRatio,
        wallThicknessMetres,
        widthOfEdgeInsulation,
        selectedApplicationType,
        averageWindShieldingFactor,
        heightBelowGroundMetres,
        thermalTransmittanceWallsInUnderFloorSpaceAboveGround,
        ventilationRateMillimetresSquaredPerMetre,
        windSpeedMetresPerSecond,
        floorInsulationLayer,
        floorInsulationReferenceType,
        floorInsulationLayerThickness,
        floorInsulationThermalConductivity,
        floorInsulationLayerInstanceId,
        isWarmSteelFramedWall,
        is50To80MillimetreFlange,
        studDepthMillimetres,
        studSpacingMillimetres,
        rainscreenCladdingDetailsPartial,
        drainageFx,
        precipitationForArea,
        pitchAngle,
        calculationNotes,
        selectedApplicationName,
        pitchedRoofWithLoftDetails,
        productSectorId,
        projectInsulationVolumeM2,
        props,
    ]);
    const handlePerimeterAreaRatioChanged = (event) => {
        const targetValue = event.target.value;
        if (event.target.value !== '') {
            setLocalState(prevState => {
                return Object.assign(Object.assign({}, prevState), { perimeterMetres: '', areaMetresSquared: '', perimeterAreaRatio: targetValue });
            });
        }
        else {
            setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { perimeterAreaRatio: targetValue })));
        }
    };
    const handlePerimeterMetresChanged = (event) => {
        const targetValue = event.target.value;
        setLocalState(prevState => {
            return setPeremiterAndArea(prevState, targetValue, prevState.areaMetresSquared);
        });
    };
    const handleAreaMetresSquaredChanged = (event) => {
        const targetValue = event.target.value;
        setLocalState(prevState => {
            return setPeremiterAndArea(prevState, prevState.perimeterMetres, targetValue);
        });
    };
    const setPeremiterAndArea = (prevState, perimeterMetres, areaMetresSquared) => {
        const perimeterAreaRatio = Number(perimeterMetres) / Number(areaMetresSquared);
        if (isPerimeterAndAreaInvalid(perimeterMetres, areaMetresSquared, perimeterAreaRatio)) {
            return Object.assign(Object.assign({}, prevState), { perimeterMetres, areaMetresSquared, perimeterAreaRatio: '' });
        }
        else {
            return Object.assign(Object.assign({}, prevState), { perimeterMetres, areaMetresSquared, perimeterAreaRatio: perimeterAreaRatio.toFixed(3) });
        }
    };
    const isPerimeterAndAreaInvalid = (perimeterMetres, areaMetresSquared, perimeterAreaRatio) => {
        return perimeterMetres === '' || areaMetresSquared === '' || isNaN(perimeterAreaRatio) || !isFinite(perimeterAreaRatio);
    };
    const canSubmit = () => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (calculation === null || calculation === void 0 ? void 0 : calculation.locked)
            return false;
        const isSoilTypeValid = (applicationTypeHasSoilType(selectedApplicationType) && Number(localState.soilTypeId) > 0) ||
            !applicationTypeHasSoilType(selectedApplicationType);
        const isHeightBelowGroundMetresValid = (applicationTypeHasHeightBelowGround(selectedApplicationType) && Number(localState.heightBelowGroundMetres) > 0) ||
            !applicationTypeHasHeightBelowGround(selectedApplicationType);
        const isWindshieldingValid = (applicationTypeHasWindShielding(selectedApplicationType) &&
            Number(localState.ventilationRateMillimetresSquaredPerMetre) > 0 &&
            Number(localState.windSpeedMetresPerSecond) > 0 &&
            Number(localState.averageWindShieldingFactor) > 0 &&
            Number(localState.thermalTransmittanceWallsInUnderFloorSpaceAboveGround) > 0) ||
            !applicationTypeHasWindShielding(selectedApplicationType);
        const isFloorInsulationDetailsValid = (applicationTypeHasFloorInsulationDetails(selectedApplicationType) &&
            ((localState.floorInsulationReferenceType === FloorInsulationLayerReferenceType.None &&
                ((_a = localState.floorInsulationLayer) === null || _a === void 0 ? void 0 : _a.id) == null &&
                localState.floorInsulationLayerThickness == null &&
                localState.floorInsulationThermalConductivity == null) ||
                // If master layer is selected, the thermal conductivity is not validated as the user cannot set it, and there are some cases where it cannot be set in the frontend.
                // Therefore, setting it has moved completely to the backend for consistency
                (localState.floorInsulationReferenceType === FloorInsulationLayerReferenceType.Master &&
                    ((_b = localState.floorInsulationLayer) === null || _b === void 0 ? void 0 : _b.id) != null &&
                    isStringValidPostiveNumber(localState.floorInsulationLayerThickness)) ||
                (localState.floorInsulationReferenceType === FloorInsulationLayerReferenceType.Custom &&
                    ((_c = localState.floorInsulationLayer) === null || _c === void 0 ? void 0 : _c.id) == null &&
                    isStringValidPostiveNumber(localState.floorInsulationLayerThickness) &&
                    isStringValidPostiveNumber(localState.floorInsulationThermalConductivity)))) ||
            !applicationTypeHasFloorInsulationDetails(selectedApplicationType);
        const isPaRatioValid = (applicationTypeHasPaRatio(selectedApplicationType) &&
            isStringValidPostiveNumber(localState.perimeterAreaRatio) &&
            !isNaN(Number(localState.perimeterAreaRatio)) &&
            isFinite(Number(localState.perimeterAreaRatio))) ||
            !applicationTypeHasPaRatio(selectedApplicationType);
        const isSteelFramedWallValid = (applicationTypeIsSteelFrameWall(selectedApplicationType) &&
            Number(localState.studSpacingMillimetres) > 0 &&
            Number(localState.studSpacingMillimetres) !== Infinity &&
            Number(localState.studDepthMillimetres) > 0 &&
            Number(localState.studDepthMillimetres) !== Infinity) ||
            !applicationTypeIsSteelFrameWall(selectedApplicationType);
        const isWallThicknessValid = (applicationTypeHasWallThickness(selectedApplicationType) && isStringValidPostiveNumber(localState.wallThicknessMetres)) ||
            !applicationTypeHasWallThickness(selectedApplicationType);
        const isInsulationWidthValid = (applicationTypeHasInsulationWidth(selectedApplicationType) && isStringValidPostiveNumber(localState.widthOfEdgeInsulation)) ||
            !applicationTypeHasInsulationWidth(selectedApplicationType);
        const isRainscreenCladdingValid = (applicationTypeHasRainscreenCladding(selectedApplicationType) &&
            ((Number((_d = localState.rainscreenCladdingDetailsPartial) === null || _d === void 0 ? void 0 : _d.chiValue) > 0 &&
                Number((_e = localState.rainscreenCladdingDetailsPartial) === null || _e === void 0 ? void 0 : _e.bracketsPerMetreSquared) > 0) ||
                localState.rainscreenCladdingDetailsPartial == null)) ||
            !applicationTypeHasRainscreenCladding(selectedApplicationType);
        const isInvertedRoofValid = (applicationTypeIsInvertedRoof(selectedApplicationType) &&
            Number((_f = localState.drainageFx) === null || _f === void 0 ? void 0 : _f.id) > 0 &&
            isStringValidPostiveNumber((_g = localState.drainageFx) === null || _g === void 0 ? void 0 : _g.fxFactor) &&
            Number((_h = localState.precipitationForArea) === null || _h === void 0 ? void 0 : _h.id) > 0) ||
            !applicationTypeIsInvertedRoof(selectedApplicationType);
        const isPitchAngleValid = (applicationTypeHasPitchAngle(selectedApplicationType) &&
            isStringNotNullishOrWhitespace(localState.pitchAngle) &&
            Number(localState.pitchAngle) >= 0 &&
            Number(localState.pitchAngle) <= 90) ||
            !applicationTypeHasPitchAngle(selectedApplicationType);
        const isPitchedRoofWithVentilatedLoftSpaceValid = (applicationTypeIsPitchedRoofWithVentilatedLoftSpace(selectedApplicationType) &&
            isStringNotNullishOrWhitespace(pitchedRoofWithLoftDetails === null || pitchedRoofWithLoftDetails === void 0 ? void 0 : pitchedRoofWithLoftDetails.loftLayerInstanceId) &&
            Number(pitchedRoofWithLoftDetails === null || pitchedRoofWithLoftDetails === void 0 ? void 0 : pitchedRoofWithLoftDetails.roofCharacteristicId) > 0 &&
            Number(pitchedRoofWithLoftDetails === null || pitchedRoofWithLoftDetails === void 0 ? void 0 : pitchedRoofWithLoftDetails.thicknessMillimetres) > 0) ||
            (!applicationTypeIsPitchedRoofWithVentilatedLoftSpace(selectedApplicationType) && pitchedRoofWithLoftDetails === undefined);
        return (isSoilTypeValid &&
            isWallThicknessValid &&
            isInsulationWidthValid &&
            isPaRatioValid &&
            isHeightBelowGroundMetresValid &&
            isWindshieldingValid &&
            isFloorInsulationDetailsValid &&
            isPaRatioValid &&
            isHeightBelowGroundMetresValid &&
            isWindshieldingValid &&
            isSteelFramedWallValid &&
            isRainscreenCladdingValid &&
            isInvertedRoofValid &&
            isPitchAngleValid &&
            isPitchedRoofWithVentilatedLoftSpaceValid);
    };
    function renderNoteTabLabel() {
        return (React.createElement("span", { "data-qa-id": "notesTabLabel" },
            "Notes",
            React.createElement("span", { className: constructionStyles.notesTabLabelIcon },
                React.createElement(Chat, { classes: {
                        root: constructionStyles.notesTabLabelIcon,
                    }, "data-qa-id": "notesTabLabelIcon", fontSize: 'small' }))));
    }
    return (React.createElement(Dialog, { open: true, onClose: props.onClose, "aria-labelledby": "modal-construction-title" },
        React.createElement("form", { "data-qa-id": "constructionModalForm", onSubmit: handleSubmit, noValidate: true },
            React.createElement("div", { className: styles.modalHeader },
                React.createElement(DialogTitle, { id: "modal-construction-title" },
                    React.createElement(Typography, { component: "span", variant: "h5" }, "Edit Construction"))),
            React.createElement("div", { className: styles.modalContent },
                React.createElement(DialogContent, null,
                    React.createElement(Grid, { container: true, spacing: 3 },
                        React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement(InputLabel, { "data-qa-id": "applicationTypeLabel", id: "calculation-application-type" }, "Application Type"),
                            React.createElement(Select, { fullWidth: true, "data-qa-id": "applicationTypeInput", id: "calculation-application-type", value: applicationTypeId, onChange: handleApplicationTypeChange, variant: "outlined", disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked) }, applicationTypes.map(applicationType => {
                                return (React.createElement(MenuItem, { key: applicationType.id, value: applicationType.id, "data-qa-id": `applicationTypeInput-option-${applicationType.name}` }, applicationType.name));
                            }))),
                        React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement(InputLabel, { "data-qa-id": "productSectorLabel", id: "calculation-product-sector" }, "Product Sector"),
                            React.createElement(Select, { fullWidth: true, "data-qa-id": "productSectorInput", id: "calculation-product-sector", value: productSectorId, onChange: handleProductSectorChange, variant: "outlined", disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked) }, productSectors.map(productSelector => {
                                return (React.createElement(MenuItem, { key: productSelector.id, value: productSelector.id, "data-qa-id": `productSectorInput-option-${productSelector.sector}` }, productSelector.sector));
                            }))),
                        React.createElement(Grid, { item: true, xs: 6 }),
                        React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement(InputLabel, { "data-qa-id": "projectInsulationInputVolumeM2Label" }, "Project Insulation Volume M2"),
                            React.createElement(TextField, { id: "contactInfoProjectInsulationVolumeM2Input", "data-qa-id": "contactInfoProjectInsulationVolumeM2Input", fullWidth: true, variant: "outlined", value: localState.projectInsulationVolumeM2, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: handleProjectInsulationVolumeM2Changed }))),
                    React.createElement(React.Fragment, null,
                        React.createElement(Tabs, { className: styles.tabs, value: selectedTab, onChange: (event, selectedTab) => setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { selectedTab }))), indicatorColor: "primary", textColor: "primary", "aria-label": "further-details-tabs", "data-qa-id": "further-details-tabs" },
                            React.createElement(Tab, { disableRipple: true, disabled: !applicationTypeHasConstructionDetails(selectedApplicationType), className: styles.tab, label: "Construction Details", id: "construction-details-tab", "aria-controls": "construction-details-panel", "data-qa-id": "construction-details-tab" }),
                            React.createElement(Tab, { disableRipple: true, disabled: !applicationTypeHasRainscreenCladding(selectedApplicationType), className: styles.tab, label: "Rainscreen Cladding", id: "rainscreen-cladding-tab", "aria-controls": "rainscreen-cladding-panel", "data-qa-id": "rainscreen-cladding-tab" }),
                            React.createElement(Tab, { disableRipple: true, className: styles.tab, label: renderNoteTabLabel(), id: "notes-tab", "aria-controls": "notes-tab-panel", "data-qa-id": "notes-tab" })),
                        localState.selectedTab !== false && (React.createElement("div", { className: styles.tabbedContentContainer, "data-qa-id": "tab-panels-container" },
                            applicationTypeHasConstructionDetails(selectedApplicationType) && (React.createElement(TabPanel, { id: "construction-details-panel", "data-qa-id": "construction-details-panel", targetIndex: 0, currentTabIndex: localState.selectedTab, "aria-labelledby": "construction-details-tab" },
                                React.createElement("div", { className: styles.tabContent },
                                    React.createElement(Grid, { container: true, item: true, xs: 12 },
                                        applicationTypeHasPaRatio(selectedApplicationType) && (React.createElement(Grid, { container: true, item: true, xs: 6, spacing: 3 },
                                            React.createElement(Grid, { item: true, xs: 3 },
                                                React.createElement(InputLabel, { htmlFor: "modal-construction-details-perimeter", "data-qa-id": "constructionDetailsPerimeterLabel" }, "Perimeter"),
                                                React.createElement(Grid, { container: true, alignItems: "center" },
                                                    React.createElement(Grid, { item: true, xs: 10 },
                                                        React.createElement(TextField, { id: "modal-construction-details-perimeter", "data-qa-id": "constructionDetailsPerimeterInput", variant: "outlined", value: localState.perimeterMetres || '', onChange: handlePerimeterMetresChanged, disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked) })),
                                                    React.createElement(Grid, { item: true, xs: 2 },
                                                        React.createElement("div", { className: styles.inputUnit, "data-qa-id": "constructionDetailsPerimeterUnits" }, "m")))),
                                            React.createElement(Grid, { item: true, xs: 3 },
                                                React.createElement(InputLabel, { htmlFor: "modal-construction-details-area", "data-qa-id": "constructionDetailsAreaLabel" }, "Area"),
                                                React.createElement(Grid, { container: true, alignItems: "center" },
                                                    React.createElement(Grid, { item: true, xs: 10 },
                                                        React.createElement(TextField, { id: "modal-construction-details-area", "data-qa-id": "constructionDetailsAreaInput", variant: "outlined", value: localState.areaMetresSquared || '', onChange: handleAreaMetresSquaredChanged, disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked) })),
                                                    React.createElement(Grid, { item: true, xs: 2 },
                                                        React.createElement("div", { className: styles.inputUnit, "data-qa-id": "constructionDetailsAreaUnits" }, "m\u00B2")))),
                                            React.createElement(Grid, { item: true, xs: 1 },
                                                React.createElement(InputLabel, null),
                                                React.createElement(VerticalAlign, { align: "center" },
                                                    React.createElement("div", { className: styles.standaloneUnit }, "="))),
                                            React.createElement(Grid, { item: true, xs: 3 },
                                                React.createElement(InputLabel, { htmlFor: "modal-construction-details-ratio", "data-qa-id": "constructionDetailsRatioLabel" }, "P/A Ratio"),
                                                React.createElement(Grid, { container: true, alignItems: "center" },
                                                    React.createElement(Grid, { item: true, xs: 10 },
                                                        React.createElement(TextField, { id: "modal-construction-details-ratio", "data-qa-id": "constructionDetailsRatioInput", variant: "outlined", value: localState.perimeterAreaRatio || '', onChange: handlePerimeterAreaRatioChanged, disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked) })))))),
                                        React.createElement(Grid, { container: true, item: true, xs: 6, spacing: 3 },
                                            applicationTypeHasWallThickness(selectedApplicationType) && (React.createElement(Grid, { item: true, xs: 4 },
                                                React.createElement(InputLabel, { htmlFor: "modal-construction-details-wall-thickness", "data-qa-id": "constructionDetailsWallThicknessLabel" }, "Wall Thickness"),
                                                React.createElement(Grid, { container: true, alignItems: "center" },
                                                    React.createElement(Grid, { item: true, xs: 10 },
                                                        React.createElement(TextField, { id: "modal-construction-details-wall-thickness", "data-qa-id": "constructionDetailsWallThicknessInput", variant: "outlined", value: wallThicknessMetres || '', disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: (event) => {
                                                                const value = event.target.value;
                                                                setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { wallThicknessMetres: value })));
                                                            } })),
                                                    React.createElement(Grid, { item: true, xs: 2 },
                                                        React.createElement("div", { className: styles.inputUnit, "data-qa-id": "constructionDetailsWallThicknessUnits" }, "m"))))),
                                            applicationTypeHasHeightBelowGround(selectedApplicationType) && (React.createElement(Grid, { item: true, xs: 4 },
                                                React.createElement(InputLabel, { htmlFor: "modal-construction-details-height-below-ground", "data-qa-id": "constructionDetailsHeightBelowGroundLabel" }, "Height B. Ground"),
                                                React.createElement(Grid, { container: true, alignItems: "center" },
                                                    React.createElement(Grid, { item: true, xs: 10 },
                                                        React.createElement(TextField, { id: "modal-construction-height-below-ground", "data-qa-id": "constructionDetailsHeightBelowGroundInput", variant: "outlined", value: heightBelowGroundMetres || '', disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: (event) => {
                                                                const value = event.target.value;
                                                                setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { heightBelowGroundMetres: value })));
                                                            } })),
                                                    React.createElement(Grid, { item: true, xs: 2 },
                                                        React.createElement("div", { className: styles.inputUnit, "data-qa-id": "constructionDetailsHeightBelowGroundUnits" }, "m"))))),
                                            applicationTypeHasInsulationWidth(selectedApplicationType) && (React.createElement(Grid, { item: true, xs: 4 },
                                                React.createElement(InputLabel, { htmlFor: "modal-construction-details-insulation-width", "data-qa-id": "constructionDetailsInsulationWidthLabel" }, "Insulation Width"),
                                                React.createElement(Grid, { container: true, alignItems: "center" },
                                                    React.createElement(Grid, { item: true, xs: 10 },
                                                        React.createElement(TextField, { id: "modal-construction-details-insulation-width", "data-qa-id": "constructionDetailsInsulationWidthInput", variant: "outlined", value: widthOfEdgeInsulation || '', disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: (event) => {
                                                                const value = event.target.value;
                                                                setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { widthOfEdgeInsulation: value })));
                                                            } })),
                                                    React.createElement(Grid, { item: true, xs: 2 },
                                                        React.createElement("div", { className: styles.inputUnit, "data-qa-id": "constructionDetailsInsulationWidthUnits" }, "mm"))))),
                                            applicationTypeHasWindShielding(selectedApplicationType) && (React.createElement(Grid, { item: true, xs: 4 },
                                                React.createElement(InputLabel, { htmlFor: "modal-construction-details-ventilation-rate", "data-qa-id": "constructionDetailsVentilationRateLabel" }, "Ventilation rate"),
                                                React.createElement(Grid, { container: true, alignItems: "center" },
                                                    React.createElement(Grid, { item: true, xs: 10 },
                                                        React.createElement(TextField, { id: "modal-construction-details-ventilation-rate", "data-qa-id": "constructionDetailsVentilationRateInput", variant: "outlined", value: localState.ventilationRateMillimetresSquaredPerMetre || '', disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: (event) => {
                                                                const value = event.target.value;
                                                                setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { ventilationRateMillimetresSquaredPerMetre: value })));
                                                            } })),
                                                    React.createElement(Grid, { item: true, xs: 2 },
                                                        React.createElement("div", { className: styles.inputUnit, "data-qa-id": "constructionDetailsVentilationRateUnits" },
                                                            "mm2",
                                                            React.createElement("br", null),
                                                            "/m"))))))),
                                    (applicationTypeHasSoilType(selectedApplicationType) ||
                                        applicationTypeHasWindShielding(selectedApplicationType)) && (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: styles.verticalDivider }),
                                        React.createElement(Grid, { container: true, item: true, xs: 12 },
                                            applicationTypeHasSoilType(selectedApplicationType) && (React.createElement(Grid, { container: true, item: true, xs: 6, spacing: 3 },
                                                React.createElement(Grid, { item: true, xs: 10 },
                                                    React.createElement(InputLabel, { "data-qa-id": "constructionDetailsSoilTypeLabel", id: "modal-construction-details-soil-type" }, "Soil Type"),
                                                    React.createElement(Select, { fullWidth: true, "data-qa-id": "constructionDetailsSoilTypeInput", id: "modal-construction-details-soil-type", value: localState.soilTypeId, disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: (event) => setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { soilTypeId: Number(event.target.value) }))), variant: "outlined" }, soilTypes.map(soilType => {
                                                        return (React.createElement(MenuItem, { key: soilType.id, value: soilType.id }, soilType.description));
                                                    }))))),
                                            applicationTypeHasWindShielding(selectedApplicationType) && (React.createElement(Grid, { container: true, item: true, xs: 6, spacing: 3 },
                                                React.createElement(Grid, { item: true, xs: 4 },
                                                    React.createElement(InputLabel, { htmlFor: "modal-construction-details-thermal-trans", "data-qa-id": "constructionDetailsThermalTransLabel" }, "Thermal trans"),
                                                    React.createElement(Grid, { container: true, alignItems: "center" },
                                                        React.createElement(Grid, { item: true, xs: 10 },
                                                            React.createElement(TextField, { id: "modal-construction-details-thermal-trans", "data-qa-id": "constructionDetailsThermalTransInput", variant: "outlined", disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), value: localState.thermalTransmittanceWallsInUnderFloorSpaceAboveGround || '', onChange: (event) => {
                                                                    const value = event.target.value;
                                                                    setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { thermalTransmittanceWallsInUnderFloorSpaceAboveGround: value })));
                                                                } })),
                                                        React.createElement(Grid, { item: true, xs: 2 },
                                                            React.createElement("div", { className: styles.inputUnit, "data-qa-id": "constructionDetailsThermalTransUnits" }, "m")))),
                                                React.createElement(Grid, { item: true, xs: 4 },
                                                    React.createElement(InputLabel, { htmlFor: "modal-construction-details-windspeed", "data-qa-id": "constructionDetailsWindspeedLabel" }, "Windspeed"),
                                                    React.createElement(Grid, { container: true, alignItems: "center" },
                                                        React.createElement(Grid, { item: true, xs: 10 },
                                                            React.createElement(TextField, { id: "modal-construction-details-windspeed", "data-qa-id": "constructionDetailsWindspeedInput", variant: "outlined", disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), value: localState.windSpeedMetresPerSecond || '', onChange: (event) => {
                                                                    const value = event.target.value;
                                                                    setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { windSpeedMetresPerSecond: value })));
                                                                } })),
                                                        React.createElement(Grid, { item: true, xs: 2 },
                                                            React.createElement("div", { className: styles.inputUnit, "data-qa-id": "constructionDetailsWindspeedUnits" }, "m/s")))),
                                                React.createElement(Grid, { item: true, xs: 4 },
                                                    React.createElement(InputLabel, { htmlFor: "modal-construction-details-average-wind-shielding", "data-qa-id": "constructionDetailsAverageWindShieldingLabel" }, "Avg wind shielding"),
                                                    React.createElement(Grid, { container: true, alignItems: "center" },
                                                        React.createElement(Grid, { item: true, xs: 10 },
                                                            React.createElement(TextField, { id: "modal-construction-details-average-wind-shielding", "data-qa-id": "constructionDetailsAverageWindShieldingInput", variant: "outlined", value: localState.averageWindShieldingFactor || '', disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: (event) => {
                                                                    const value = event.target.value;
                                                                    setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { averageWindShieldingFactor: value })));
                                                                } }))))))))),
                                    applicationTypeHasFloorInsulationDetails(selectedApplicationType) && (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: styles.verticalDivider }),
                                        React.createElement(Grid, { container: true, item: true, xs: 12 },
                                            React.createElement(Grid, { container: true, item: true, xs: 6, spacing: 3 },
                                                React.createElement(Grid, { item: true, xs: 10 },
                                                    React.createElement(InputLabel, { htmlFor: "modal-construction-details-floor-insulation-layer", "data-qa-id": "constructionDetailsFloorInsulationLayerLabel" }, "Floor Insulation"),
                                                    React.createElement(Grid, { container: true, alignItems: "center" },
                                                        React.createElement(Grid, { item: true, xs: 12 },
                                                            React.createElement(Autocomplete, { id: "modal-construction-details-floor-insulation-layer", "data-qa-id": "constructionDetailsFloorInsulationLayerInput", options: floorReferenceOptions, autoHighlight: true, getOptionLabel: (option) => option.name, value: localState.floorInsulationLayer, disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: (event, layer) => {
                                                                    if (!layer) {
                                                                        setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { floorInsulationLayerThickness: null, floorInsulationReferenceType: null, floorInsulationThermalConductivity: null, floorInsulationLayer: null })));
                                                                        return;
                                                                    }
                                                                    if (layer.id != null) {
                                                                        setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { floorInsulationLayerThickness: layer.defaultThickness, floorInsulationReferenceType: FloorInsulationLayerReferenceType.Master, floorInsulationThermalConductivity: null, floorInsulationLayer: layer })));
                                                                        return;
                                                                    }
                                                                    else {
                                                                        if (layer.type === FloorInsulationLayerReferenceType.None) {
                                                                            setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { floorInsulationLayerThickness: null, floorInsulationReferenceType: FloorInsulationLayerReferenceType.None, floorInsulationThermalConductivity: null, floorInsulationLayer: layer })));
                                                                            return;
                                                                        }
                                                                        else {
                                                                            setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { floorInsulationLayerThickness: null, floorInsulationReferenceType: FloorInsulationLayerReferenceType.Custom, floorInsulationThermalConductivity: null, floorInsulationLayer: layer })));
                                                                            return;
                                                                        }
                                                                    }
                                                                }, forcePopupIcon: true, freeSolo: true, filterOptions: (options, params) => {
                                                                    const filtered = filter(options, params);
                                                                    return filtered;
                                                                }, renderInput: params => (React.createElement(TextField, Object.assign({}, params, { placeholder: "Browse catalogue...", variant: "outlined", autoComplete: "off" // Disable browser autocomplete and autofill
                                                                 }))) }))))),
                                            React.createElement(Grid, { container: true, item: true, xs: 6, spacing: 3 },
                                                floorInsulationReferenceType === FloorInsulationLayerReferenceType.Custom && (React.createElement(Grid, { item: true, xs: 4 },
                                                    React.createElement(InputLabel, { htmlFor: "modal-construction-details-floor-insulation-thermal-conductivity", "data-qa-id": "constructionDetailsFloorInsulationThermalConductivityLabel" }, "Therm. Cond."),
                                                    React.createElement(Grid, { container: true, alignItems: "center" },
                                                        React.createElement(Grid, { item: true, xs: 9 },
                                                            React.createElement(TextField, { id: "modal-construction-details-floor-insulation-thermal-conductivity", "data-qa-id": "constructionDetailsFloorInsulationThermalConductivityInput", variant: "outlined", value: localState.floorInsulationThermalConductivity || '', disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: (event) => {
                                                                    const value = event.target.value;
                                                                    setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { floorInsulationThermalConductivity: value })));
                                                                } })),
                                                        React.createElement(Grid, { item: true, xs: 3 },
                                                            React.createElement("div", { className: styles.inputUnit, "data-qa-id": "constructionDetailsFloorInsulationThermalConductivityUnits" }, "W/mK"))))),
                                                floorInsulationReferenceType != null &&
                                                    floorInsulationReferenceType !== FloorInsulationLayerReferenceType.None && (React.createElement(Grid, { item: true, xs: 4 },
                                                    React.createElement(InputLabel, { htmlFor: "modal-construction-details-floor-insulation-thickness", "data-qa-id": "constructionDetailsFloorInsulationThicknessLabel" }, "Thickness"),
                                                    React.createElement(Grid, { container: true, alignItems: "center" },
                                                        React.createElement(Grid, { item: true, xs: 10 },
                                                            React.createElement(TextField, { id: "modal-construction-details-floor-insulation-thickness", "data-qa-id": "constructionDetailsFloorInsulationThicknessInput", variant: "outlined", value: localState.floorInsulationLayerThickness || '', disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: (event) => {
                                                                    const value = event.target.value;
                                                                    setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { floorInsulationLayerThickness: value })));
                                                                } })),
                                                        React.createElement(Grid, { item: true, xs: 2 },
                                                            React.createElement("div", { className: styles.inputUnit, "data-qa-id": "constructionDetailsfloorInsulationThicknessUnits" }, "mm"))))))))),
                                    applicationTypeIsSteelFrameWall(selectedApplicationType) && (React.createElement(React.Fragment, null,
                                        React.createElement(Grid, { container: true, item: true, xs: 12 },
                                            React.createElement(Grid, { item: true, xs: 4 },
                                                React.createElement(FormLabel, { classes: {
                                                        root: constructionStyles.formLabelUpsize,
                                                    } }, "Cold or Hybrid Steel Frame")),
                                            React.createElement(Grid, { item: true, xs: 4 },
                                                React.createElement(FormControlLabel, { "data-qa-id": "constructionDetailsWarmSteelFrameLabel", control: React.createElement(Switch, { color: "primary", checked: isWarmSteelFramedWall == null ? false : isWarmSteelFramedWall, "data-qa-id": "constructionDetailsWarmSteelFrameSwitch", name: "isWarmSteelFramedWall", disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: () => {
                                                            setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { isWarmSteelFramedWall: !prevState.isWarmSteelFramedWall })));
                                                        } }), label: "Warm Steel Frame", labelPlacement: "end" })),
                                            React.createElement("div", { className: styles.verticalDivider }),
                                            React.createElement(Grid, { container: true },
                                                React.createElement(Grid, { item: true, xs: 4 },
                                                    React.createElement(InputLabel, { disabled: isWarmSteelFramedWall ? isWarmSteelFramedWall : undefined, htmlFor: "modal-construction-details-stud-depth", "data-qa-id": "constructionDetailsStudDepthLabel" }, "Stud depth"),
                                                    React.createElement(Grid, { container: true, alignItems: "center" },
                                                        React.createElement(Grid, { item: true, xs: 5 },
                                                            React.createElement(TextField, { disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked) || !!isWarmSteelFramedWall, id: "modal-construction-details-stud-depth", "data-qa-id": "constructionDetailsStudDepthInput", variant: "outlined", value: localState.studDepthMillimetres || '', onChange: (event) => {
                                                                    const value = event.target.value;
                                                                    setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { studDepthMillimetres: value })));
                                                                } })),
                                                        React.createElement(Grid, { item: true, xs: 2 },
                                                            React.createElement("div", { className: styles.inputUnit, "data-qa-id": "constructionDetailsStudDepthUnits" }, "mm")))),
                                                React.createElement(Grid, { item: true, xs: 4 },
                                                    React.createElement(InputLabel, { disabled: isWarmSteelFramedWall ? isWarmSteelFramedWall : undefined, htmlFor: "modal-construction-details-stud-spacing", "data-qa-id": "constructionDetailsStudSpacingLabel" }, "Stud spacing"),
                                                    React.createElement(Grid, { container: true, alignItems: "center" },
                                                        React.createElement(Grid, { item: true, xs: 5 },
                                                            React.createElement(TextField, { id: "modal-construction-details-stud-spacing", "data-qa-id": "constructionDetailsStudSpacingInput", variant: "outlined", value: localState.studSpacingMillimetres || '', disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked) || !!isWarmSteelFramedWall, onChange: (event) => {
                                                                    const value = event.target.value;
                                                                    setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { studSpacingMillimetres: value })));
                                                                } })),
                                                        React.createElement(Grid, { item: true, xs: 2 },
                                                            React.createElement("div", { className: styles.inputUnit, "data-qa-id": "constructionDetailsStudSpacingUnits" }, "mm")))),
                                                React.createElement("div", { className: styles.verticalDivider }),
                                                React.createElement(Grid, { container: true },
                                                    React.createElement(Grid, { item: true, xs: 12 },
                                                        React.createElement(FormControl, { disabled: isWarmSteelFramedWall ? isWarmSteelFramedWall : undefined },
                                                            React.createElement(RadioGroup, { name: "is50To80MillimetreFlange", value: is50To80MillimetreFlange === null ? 'false' : is50To80MillimetreFlange.toString(), "data-qa-id": "constructionDetailsLargeFlangeSwitch", onChange: (event) => {
                                                                    const value = event.target.value === 'true';
                                                                    setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { is50To80MillimetreFlange: value })));
                                                                }, row: true },
                                                                React.createElement(FormControlLabel, { disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), value: "false", control: React.createElement(Radio, null), label: "Not Exceeding 50mm" }),
                                                                React.createElement(FormControlLabel, { disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), value: "true", control: React.createElement(Radio, null), label: "Not Exceeding 80mm" }))))))))),
                                    applicationTypeIsInvertedRoof(selectedApplicationType) && (React.createElement(React.Fragment, null,
                                        React.createElement(Grid, { container: true, spacing: 3 },
                                            React.createElement(Grid, { item: true, xs: 5 },
                                                React.createElement(InputLabel, { "data-qa-id": "ballastTypeLabel", id: "calculation-details-ballast-type" }, "Ballast Type"),
                                                React.createElement(Select, { fullWidth: true, "data-qa-id": "ballastTypeInput", id: "calculation-details-ballast-type", value: (_39 = drainageFx === null || drainageFx === void 0 ? void 0 : drainageFx.id) !== null && _39 !== void 0 ? _39 : '', disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: (event) => {
                                                        const value = event.target.value;
                                                        setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { drainageFx: drainageFxTypes.filter(d => d.id === value)[0] })));
                                                    }, variant: "outlined" }, drainageFxTypes.map(drainageFxType => {
                                                    return (React.createElement(MenuItem, { key: drainageFxType.id, value: drainageFxType.id }, drainageFxType.covering));
                                                }))),
                                            React.createElement(Grid, { item: true, xs: 2 },
                                                React.createElement(InputLabel, { htmlFor: "modal-construction-details-drainage-fx", "data-qa-id": "constructionDetailsDrainageFxLabel" }, "Drainage (f.x)"),
                                                React.createElement(Grid, { container: true, alignItems: "center" },
                                                    React.createElement(Grid, { item: true, xs: 10 },
                                                        React.createElement(TextField, { id: "modal-construction-details-details-drainage-fx", "data-qa-id": "constructionDetailsDrainageFxInput", variant: "outlined", value: (_40 = localState.drainageFx) === null || _40 === void 0 ? void 0 : _40.fxFactor, disabled: true })))),
                                            React.createElement(Grid, { item: true, xs: 2 },
                                                React.createElement(InputLabel, { htmlFor: "modal-construction-details-details-postcode-area", "data-qa-id": "constructionDetailsPostcodeAreaLabel" }, "Postcode Area"),
                                                React.createElement(Autocomplete, { id: "modal-construction-details-details-postcode-are", "data-qa-id": "constructionDetailsPostcodeAreaInput", options: precipitationByPostCodeAreas, autoHighlight: true, getOptionLabel: (option) => option.postCodeArea, value: precipitationForArea, disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: (event, value) => {
                                                        setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { precipitationForArea: value })));
                                                    }, renderInput: params => (React.createElement(TextField, Object.assign({}, params, { variant: "outlined", inputProps: Object.assign(Object.assign({}, params.inputProps), { autoComplete: 'new-password' }) }))) })),
                                            React.createElement(Grid, { item: true, xs: 2 },
                                                React.createElement(InputLabel, { htmlFor: "modal-construction-details-fainfall", "data-qa-id": "constructionDetailsRainfallLabel" }, "Rainfall"),
                                                React.createElement(Grid, { container: true, alignItems: "center" },
                                                    React.createElement(Grid, { item: true, xs: 10 },
                                                        React.createElement(TextField, { id: "modal-construction-details-rainfall", "data-qa-id": "constructionDetailsRainfallInput", variant: "outlined", value: (_42 = (_41 = localState.precipitationForArea) === null || _41 === void 0 ? void 0 : _41.rainfallMillimetres) !== null && _42 !== void 0 ? _42 : '', disabled: true }))))))),
                                    applicationTypeHasPitchAngle(selectedApplicationType) && (React.createElement(Grid, { container: true, spacing: 3 },
                                        React.createElement(Grid, { item: true, xs: 4 },
                                            React.createElement(InputLabel, { htmlFor: "modal-construction-details-pitch-angle", "data-qa-id": "constructionDetailsPitchAngleLabel" }, "Pitch Angle"),
                                            React.createElement(Grid, { container: true, alignItems: "center" },
                                                React.createElement(Grid, { item: true, xs: 3 },
                                                    React.createElement(TextField, { id: "modal-construction-details-pitch-angle", "data-qa-id": "constructionDetailsPitchAngleInput", variant: "outlined", value: localState.pitchAngle || '', disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: (event) => {
                                                            const value = event.target.value;
                                                            setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { pitchAngle: value })));
                                                        } })))))),
                                    applicationTypeIsPitchedRoofWithVentilatedLoftSpace(selectedApplicationType) && (React.createElement(Grid, { container: true, spacing: 3 },
                                        React.createElement(Grid, { item: true, xs: 11 },
                                            React.createElement(InputLabel, { htmlFor: "modal-construction-details-roof-characteristics", "data-qa-id": "constructionDetailsRoofCharacteristicsLabel" }, "Roof Characteristics"),
                                            React.createElement(Select, { fullWidth: true, "data-qa-id": "constructionDetailsRoofCharacteristicsInput", id: "modal-construction-details-roof-characteristics", value: ((_43 = localState.pitchedRoofWithLoftDetails) === null || _43 === void 0 ? void 0 : _43.roofCharacteristicId) || roofCharacteristicsOptions[0].id, disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: (event) => setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { pitchedRoofWithLoftDetails: Object.assign(Object.assign({}, prevState.pitchedRoofWithLoftDetails), { roofCharacteristicId: Number(event.target.value) }) }))), variant: "outlined" }, roofCharacteristicsOptions.map(option => {
                                                return (React.createElement(MenuItem, { key: option.id, value: option.id }, option.description));
                                            }))),
                                        React.createElement(Grid, { item: true, xs: 4 },
                                            React.createElement(InputLabel, { htmlFor: "modal-construction-details-loft-space-thickness", "data-qa-id": "constructionDetailsLoftSpaceThicknessLabel" }, "Loft Space Thickness"),
                                            React.createElement(Grid, { container: true, alignItems: "center" },
                                                React.createElement(Grid, { item: true, xs: 3 },
                                                    React.createElement(TextField, { id: "modal-construction-details-loft-space-thickness", "data-qa-id": "constructionDetailsLoftSpaceThicknessInput", variant: "outlined", value: ((_44 = localState.pitchedRoofWithLoftDetails) === null || _44 === void 0 ? void 0 : _44.thicknessMillimetres) || '', disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: (event) => {
                                                            const value = event.target.value;
                                                            setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { pitchedRoofWithLoftDetails: Object.assign(Object.assign({}, prevState.pitchedRoofWithLoftDetails), { thicknessMillimetres: value }) })));
                                                        } }))))))),
                                applicationTypeHasConstructionDetailsResults(selectedApplicationType) && (React.createElement("div", { className: styles.tabContentResults },
                                    applicationTypeHasSoilType(selectedApplicationType) && (React.createElement(Grid, { container: true, spacing: 10 },
                                        React.createElement(Grid, { item: true, xs: 6 },
                                            React.createElement("div", { className: styles.tabContentResult },
                                                React.createElement(Grid, { container: true },
                                                    React.createElement(Grid, { item: true, xs: 8, "data-qa-id": "soilThermalConductivityLabel" }, "Soil Thermal Conductivity"),
                                                    React.createElement(Grid, { item: true, xs: 4, "data-qa-id": "soilThermalConductivity" },
                                                        React.createElement("div", { className: styles.tabContentResultValueUnitContainer },
                                                            React.createElement("div", { className: styles.tabContentResultValue, "data-qa-id": "soilThermalConductivityValue" }, groundThermalConductivity !== null && groundThermalConductivity !== void 0 ? groundThermalConductivity : '0.000'),
                                                            React.createElement("div", { className: styles.tabContentResultUnit, "data-qa-id": "soilThermalConductivityUnit" }, "W/mK")))))),
                                        React.createElement(Grid, { item: true, xs: 6 },
                                            React.createElement("div", { className: styles.tabContentResult },
                                                React.createElement(Grid, { container: true },
                                                    React.createElement(Grid, { item: true, xs: 8, "data-qa-id": "soilVapourResistivityLabel" }, "Soil Vapour Resistivity"),
                                                    React.createElement(Grid, { item: true, xs: 4, "data-qa-id": "soilVapourResistivity" },
                                                        React.createElement("div", { className: styles.tabContentResultValueUnitContainer },
                                                            React.createElement("div", { className: styles.tabContentResultValue, "data-qa-id": "soilVapourResistivityValue" }, groundVapourResistivity !== null && groundVapourResistivity !== void 0 ? groundVapourResistivity : '0.000'),
                                                            React.createElement("div", { className: styles.tabContentResultUnit, "data-qa-id": "soilVapourResistivityUnit" }, "MNs/gm")))))))),
                                    applicationTypeIsInvertedRoof(selectedApplicationType) && (React.createElement(Grid, { container: true, spacing: 10 },
                                        React.createElement(Grid, { item: true, xs: 6 },
                                            React.createElement("div", { className: styles.tabContentResult },
                                                React.createElement(Grid, { container: true },
                                                    React.createElement(Grid, { item: true, xs: 8, "data-qa-id": "RainwaterCorrectionLabel" }, "Rainwater Cooling Correction"),
                                                    React.createElement(Grid, { item: true, xs: 4, "data-qa-id": "RainwaterCorrection" },
                                                        React.createElement("div", { className: styles.tabContentResultValueUnitContainer },
                                                            React.createElement("div", { className: styles.tabContentResultValue, "data-qa-id": "RainwaterCorrectionValue" }, (_45 = calculation === null || calculation === void 0 ? void 0 : calculation.rainwaterCoolingCorrectionFactor) !== null && _45 !== void 0 ? _45 : '0.000'),
                                                            React.createElement("div", { className: styles.tabContentResultUnit, "data-qa-id": "RainwaterCorrectionUnit" }, "W/m\u00B2K")))))))))))),
                            applicationTypeHasRainscreenCladding(selectedApplicationType) && (React.createElement(TabPanel, { id: "rainscreen-cladding-panel", "data-qa-id": "rainscreen-cladding-panel", targetIndex: 1, currentTabIndex: localState.selectedTab, "aria-labelledby": "rainscreen-cladding-tab" },
                                React.createElement("div", { className: styles.tabContent },
                                    React.createElement(Grid, { container: true, spacing: 3 },
                                        React.createElement(Grid, { container: true },
                                            React.createElement(Grid, { item: true, xs: 4 },
                                                React.createElement(FormControlLabel, { "data-qa-id": "applyRainscreenCladdingLabel", control: React.createElement(Switch, { color: "primary", checked: rainscreenCladdingDetailsPartial != null, "data-qa-id": "applyRainscreenCladdingSwitch", name: "isRainscreenCladdingApplied", disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: () => {
                                                            setLocalState(prevState => {
                                                                var _a, _b;
                                                                return (Object.assign(Object.assign({}, prevState), { rainscreenCladdingDetailsPartial: prevState.rainscreenCladdingDetailsPartial == null
                                                                        ? {
                                                                            chiValue: (_a = rainscreenCladdingDefaults === null || rainscreenCladdingDefaults === void 0 ? void 0 : rainscreenCladdingDefaults.chiValue) !== null && _a !== void 0 ? _a : undefined,
                                                                            bracketsPerMetreSquared: (_b = rainscreenCladdingDefaults === null || rainscreenCladdingDefaults === void 0 ? void 0 : rainscreenCladdingDefaults.bracketsPerMetreSquared) !== null && _b !== void 0 ? _b : undefined,
                                                                        }
                                                                        : undefined }));
                                                            });
                                                        } }), label: "Apply Rainscreen Cladding", labelPlacement: "end" }))),
                                        React.createElement("div", { className: styles.verticalDivider }),
                                        rainscreenCladdingDetailsPartial != null && (React.createElement(Grid, { container: true, item: true, xs: 6, spacing: 3 },
                                            React.createElement(Grid, { item: true, xs: 4 },
                                                React.createElement(InputLabel, { htmlFor: "modal-rainscreen-cladding-chi-value", "data-qa-id": "rainscreenCladdingChiValueLabel" }, "Chi Value"),
                                                React.createElement(Grid, { container: true, alignItems: "center" },
                                                    React.createElement(Grid, { item: true, xs: 10 },
                                                        React.createElement(TextField, { id: "modal-rainscreen-cladding-chi-value", "data-qa-id": "rainscreenCladdingChiValueInput", variant: "outlined", value: ((_46 = localState.rainscreenCladdingDetailsPartial) === null || _46 === void 0 ? void 0 : _46.chiValue) || '', disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: (event) => {
                                                                const value = event.target.value;
                                                                setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { rainscreenCladdingDetailsPartial: prevState.rainscreenCladdingDetailsPartial == null
                                                                        ? {
                                                                            bracketsPerMetreSquared: undefined,
                                                                            chiValue: value,
                                                                        }
                                                                        : Object.assign(Object.assign({}, prevState.rainscreenCladdingDetailsPartial), { chiValue: value }) })));
                                                            } })))),
                                            React.createElement(Grid, { item: true, xs: 4 },
                                                React.createElement(InputLabel, { htmlFor: "modal-rainscreen-cladding-brackets-per-metre-squared", "data-qa-id": "rainscreenCladdingBracketsPerMetreSquaredLabel" }, "Brackets per m\u00B2"),
                                                React.createElement(Grid, { container: true, alignItems: "center" },
                                                    React.createElement(Grid, { item: true, xs: 10 },
                                                        React.createElement(TextField, { id: "modal-rainscreen-cladding-brackets-per-metre-squared", "data-qa-id": "rainscreenCladdingBracketsPerMetreSquaredInput", variant: "outlined", value: ((_47 = localState.rainscreenCladdingDetailsPartial) === null || _47 === void 0 ? void 0 : _47.bracketsPerMetreSquared) || '', disabled: !!(calculation === null || calculation === void 0 ? void 0 : calculation.locked), onChange: (event) => {
                                                                const value = event.target.value;
                                                                setLocalState(prevState => (Object.assign(Object.assign({}, prevState), { rainscreenCladdingDetailsPartial: prevState.rainscreenCladdingDetailsPartial == null
                                                                        ? {
                                                                            bracketsPerMetreSquared: value,
                                                                            chiValue: undefined,
                                                                        }
                                                                        : Object.assign(Object.assign({}, prevState.rainscreenCladdingDetailsPartial), { bracketsPerMetreSquared: value }) })));
                                                            } })))))))),
                                React.createElement("div", { className: styles.tabContentResults },
                                    React.createElement(Grid, { container: true, spacing: 10 },
                                        React.createElement(Grid, { item: true, xs: 6 },
                                            React.createElement("div", { className: styles.tabContentResult },
                                                React.createElement(Grid, { container: true },
                                                    React.createElement(Grid, { item: true, xs: 8, "data-qa-id": "rainscreenCladdingCorrectionLabel" }, "Rainscreen Cladding Correction Factor"),
                                                    React.createElement(Grid, { item: true, xs: 4, "data-qa-id": "rainscreenCladdingCorrection" },
                                                        React.createElement("div", { className: styles.tabContentResultValueUnitContainer },
                                                            React.createElement("div", { className: styles.tabContentResultValue, "data-qa-id": "rainscreenCladdingCorrectionValue" }, rainscreenCladdingCorrectionFactor !== null && rainscreenCladdingCorrectionFactor !== void 0 ? rainscreenCladdingCorrectionFactor : '0.000'),
                                                            React.createElement("div", { className: styles.tabContentResultUnit, "data-qa-id": "rainscreenCladdingCorrectionUnit" }, "W/m\u00B2K")))))))))),
                            React.createElement(NotesTab, { selectedTab: selectedTab, calculationTitle: calculationNotes.calculationTitle, notes: calculationNotes.notes, setLocalState: setLocalState, isLocked: calculation === null || calculation === void 0 ? void 0 : calculation.locked })))))),
            React.createElement("div", { className: styles.modalActions },
                React.createElement(DialogActions, null,
                    React.createElement(Grid, { container: true, spacing: 2, justify: "flex-end" },
                        React.createElement(Grid, { container: true, item: true, xs: 2, justify: "flex-end" },
                            React.createElement(Button, { "data-qa-id": "layerCloseButton", onClick: props.onClose, variant: "outlined" }, "Cancel")),
                        React.createElement(Grid, { item: true, xs: 2 },
                            React.createElement(Button, { type: "submit", "data-qa-id": "constructionSubmitButton", fullWidth: true, disabled: !canSubmit(), onClick: handleSubmit, color: "primary", variant: "contained" }, "Save"))))))));
}
