import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputLabel, MenuItem, Select, TextField, Typography, } from '@material-ui/core';
import { Alert, Autocomplete, createFilterOptions } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import modalStyles from '../LayerModal/LayerModal.module.scss';
import styles from './ProjectInfoModal.module.scss';
import { buildingUsagesSelector, currentCalculationBusinessUnitIdSelector, interimOrCurrentCalculationSelector, projectCountyStateSelector, projectRegionsSelector, projectStageSelector } from '../../store/selectors';
import { associateProject } from '../../actions/projectActions';
import { callApi } from '../../common/api';
import { useDebounce } from '../Utilities/hooks/useDebounce';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
const config = {
    stringify: (option) => option.name,
};
const filter = createFilterOptions(config);
export function ProjectInfoModal({ onClose }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const dispatch = useDispatch();
    const calculation = useSelector(interimOrCurrentCalculationSelector);
    const currentCalculationBusinessUnitId = useSelector(currentCalculationBusinessUnitIdSelector);
    const projectUsages = useSelector(buildingUsagesSelector);
    const projectRegions = useSelector(projectRegionsSelector);
    const projectCountyStates = useSelector(projectCountyStateSelector);
    const projectStages = useSelector(projectStageSelector);
    const defaultState = {
        newProject: {
            id: null,
            name: '',
            displayId: '',
            type: '',
            businessUnitId: null,
            supportingInfo: '',
            size: '',
            projectUsage: '',
            projectUsageValue: '',
            buildingHeight: '',
            region: '',
            regionValue: '',
            enquiryType: '',
            projectStartDate: new Date().toDateString(),
            projectStage: '',
            projectStageValue: '',
            projectAddressLine1: '',
            projectAddressLine2: '',
            projectCity: '',
            projectCountyState: '',
            projectCountyStateValue: '',
            projectPostCode: '',
        },
        existingProject: null,
    };
    const [state, setState] = React.useState(defaultState);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [existingProjects, setExistingProjects] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [startDate, setStartDate] = React.useState(new Date());
    const disableButtons = (calculation === null || calculation === void 0 ? void 0 : calculation.locked) || (state.newProject ? !state.newProject.name : !((_a = state.existingProject) === null || _a === void 0 ? void 0 : _a.name));
    const debouncedSearchTerm = useDebounce(searchTerm, 300);
    const updateField = (fieldName, value) => {
        setState(currentState => (Object.assign(Object.assign({}, currentState), (state.newProject
            ? {
                newProject: Object.assign(Object.assign({}, currentState.newProject), { [fieldName]: value }),
            }
            : {
                existingProject: Object.assign(Object.assign({}, currentState.existingProject), { [fieldName]: value }),
            }))));
    };
    const handleProjectNameChange = (project) => {
        if (!project) {
            return setState(defaultState);
        }
        project.businessUnitId = currentCalculationBusinessUnitId;
        if (project.id != null) {
            setState(currentState => (Object.assign(Object.assign({}, currentState), { newProject: null, existingProject: project })));
            setStartDate(project.projectStartDate ? new Date(project.projectStartDate) : new Date());
        }
        else {
            if (state.newProject) {
                setState(currentState => (Object.assign(Object.assign({}, currentState), { newProject: Object.assign(Object.assign({}, currentState.newProject), { name: project.name, businessUnitId: project.businessUnitId }), existingProject: null })));
                setStartDate(new Date());
            }
            else {
                setState(currentState => (Object.assign(Object.assign({}, currentState), { newProject: project, existingProject: null })));
                setStartDate(project.projectStartDate ? new Date(project.projectStartDate) : new Date());
            }
        }
    };
    const handleSubmit = React.useCallback(async (event, shouldAssociateToAll) => {
        var _a, _b;
        event.preventDefault();
        try {
            if (state.newProject) {
                state.newProject.projectStartDate = (_a = startDate === null || startDate === void 0 ? void 0 : startDate.toDateString()) !== null && _a !== void 0 ? _a : '';
                await dispatch(associateProject(state.newProject, true, shouldAssociateToAll));
            }
            else if (state.existingProject) {
                state.existingProject.projectStartDate = (_b = startDate === null || startDate === void 0 ? void 0 : startDate.toDateString()) !== null && _b !== void 0 ? _b : '';
                await dispatch(associateProject(state.existingProject, false, shouldAssociateToAll));
            }
            onClose();
        }
        catch (e) {
            setError('Your project could not be saved and associated, please try again.');
        }
    }, [dispatch, state, onClose, startDate]);
    React.useEffect(() => {
        if (calculation === null || calculation === void 0 ? void 0 : calculation.project) {
            (async () => {
                var _a;
                const existingProject = await callApi(dispatch, 'GET', `/Project/${currentCalculationBusinessUnitId}/${(_a = calculation.project) === null || _a === void 0 ? void 0 : _a.id}`);
                if (existingProject) {
                    setState({
                        newProject: null,
                        existingProject,
                    });
                    setStartDate(existingProject.projectStartDate ? new Date(existingProject.projectStartDate) : null);
                }
            })();
        }
    }, [dispatch, calculation, currentCalculationBusinessUnitId]);
    React.useEffect(() => {
        if (debouncedSearchTerm) {
            (async () => {
                if (searchTerm.length < 3) {
                    return setExistingProjects([]);
                }
                const existingProjects = await callApi(dispatch, 'GET', `/Project/search/${currentCalculationBusinessUnitId}/${debouncedSearchTerm}`);
                if (existingProjects) {
                    setExistingProjects(existingProjects);
                }
            })();
        }
    }, [dispatch, searchTerm, debouncedSearchTerm, setExistingProjects, currentCalculationBusinessUnitId]);
    return (React.createElement(Dialog, { open: true, onClose: onClose, "aria-labelledby": "modal-project-info-title" },
        React.createElement("form", { "data-qa-id": "projectInfoModalForm", onSubmit: (event) => handleSubmit(event, false), noValidate: true },
            React.createElement("div", { className: modalStyles.modalHeader },
                React.createElement(DialogTitle, { id: "modal-project-info-title" },
                    React.createElement(Typography, { component: "span", variant: "h5" }, "Project Information"))),
            React.createElement("div", { className: `${[modalStyles.modalContent, styles.modalContent].join(' ')}` },
                React.createElement(DialogContent, null,
                    error && (React.createElement(Alert, { severity: "error", className: styles.alert, "data-qa-id": "projectInfoError" }, error)),
                    React.createElement(Grid, { container: true, spacing: 3 },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Grid, { container: true, spacing: 3 },
                                React.createElement(Grid, { item: true, xs: 8 },
                                    React.createElement(InputLabel, { htmlFor: "projectInfoProjectNameInput", "data-qa-id": "projectInfoProjectNameInputLabel" }, "Project Name"),
                                    React.createElement(Autocomplete, { id: "projectInfoProjectNameInput", "data-qa-id": "projectInfoProjectNameInput", options: existingProjects, autoHighlight: true, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, getOptionLabel: (option) => option.name, onInputChange: (event, searchTerm) => setSearchTerm(searchTerm), onChange: (event, project) => handleProjectNameChange(project), value: (_b = state.newProject) !== null && _b !== void 0 ? _b : state.existingProject, forcePopupIcon: true, freeSolo: true, renderOption: (project) => {
                                            var _a;
                                            return project.id != null ? (project.name) : (React.createElement(Grid, { container: true, justify: "space-between", alignItems: "center" },
                                                React.createElement("span", { "data-qa-id": "newProjectOptionName" }, project.name),
                                                React.createElement(Grid, { item: true },
                                                    React.createElement(Button, { variant: "outlined", "data-qa-id": "newProjectOptionButton" }, ((_a = state.newProject) === null || _a === void 0 ? void 0 : _a.name) ? 'Update' : '+ New Project'))));
                                        }, filterOptions: (options, params) => {
                                            const filtered = filter(options, params);
                                            if (params.inputValue !== '') {
                                                const option = state.newProject
                                                    ? Object.assign(Object.assign({}, state.newProject), { name: params.inputValue }) : Object.assign(Object.assign({}, defaultState.newProject), { name: params.inputValue });
                                                filtered.push(option);
                                            }
                                            return filtered.reduce((acc, project) => {
                                                const isNewOption = project.id === null;
                                                const isAlreadyASuggestion = existingProjects.find(p => p.name.toLowerCase() === project.name.toLowerCase());
                                                return isNewOption && isAlreadyASuggestion ? acc : [...acc, project];
                                            }, []);
                                        }, renderInput: params => (React.createElement(TextField, Object.assign({}, params, { placeholder: "Enter 3 or more characters to search...", variant: "outlined", autoComplete: "off" // Disable browser autocomplete and autofill
                                         }))) })),
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "projectInfoProjectIdInput", "data-qa-id": "projectInfoProjectIdInputLabel" }, "Project ID"),
                                    React.createElement(TextField, { id: "projectInfoProjectIdInput", "data-qa-id": "projectInfoProjectIdInput", fullWidth: true, variant: "outlined", value: state.newProject ? state.newProject.displayId : (_c = state.existingProject) === null || _c === void 0 ? void 0 : _c.displayId, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => updateField('displayId', value) }))),
                            React.createElement(Grid, { container: true, spacing: 3 },
                                React.createElement(Grid, { item: true, xs: 8 },
                                    React.createElement(InputLabel, { htmlFor: "projectInfoProjectAddressLine1Input", "data-qa-id": "projectInfoProjectAddressLine1Label" }, "Project Address Line 1"),
                                    React.createElement(TextField, { id: "projectInfoProjectAddressLine1Input", "data-qa-id": "projectInfoProjectAddressLine1Input", fullWidth: true, variant: "outlined", value: state.newProject ? state.newProject.projectAddressLine1 : (_d = state.existingProject) === null || _d === void 0 ? void 0 : _d.projectAddressLine1, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => updateField('projectAddressLine1', value) })),
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "projectInfoProjectStartDateInput", "data-qa-id": "projectInfoProjectStartDateInputLabel" }, "Project Start Date"),
                                    React.createElement(MuiPickersUtilsProvider, { utils: DateFnsUtils },
                                        React.createElement(Box, { border: 1, borderRadius: 4, borderColor: "lightGray" },
                                            React.createElement(KeyboardDatePicker, { disableToolbar: true, fullWidth: true, variant: "inline", format: "dd/MM/yyyy", margin: "normal", id: "projectInfoProjectStartDateInput", "data-qa-id": "projectInfoProjectStartDateInput", value: startDate, onChange: setStartDate, KeyboardButtonProps: {
                                                    'aria-label': 'change date',
                                                }, InputProps: { disableUnderline: true }, style: { paddingLeft: '14px' } }))))),
                            React.createElement(Grid, { container: true, spacing: 3 },
                                React.createElement(Grid, { item: true, xs: 8 },
                                    React.createElement(InputLabel, { htmlFor: "projectInfoProjectAddressLine2Input", "data-qa-id": "projectInfoProjectAddressLine2Label" }, "Project Address Line 2"),
                                    React.createElement(TextField, { id: "projectInfoProjectAddressLine2Input", "data-qa-id": "projectInfoProjectAddressLine2Input", fullWidth: true, variant: "outlined", value: state.newProject ? state.newProject.projectAddressLine2 : (_e = state.existingProject) === null || _e === void 0 ? void 0 : _e.projectAddressLine2, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => updateField('projectAddressLine2', value) })),
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "projectInfoProjectStageInput", "data-qa-id": "projectInfoProjectStageInputLabel" }, "Project Stage"),
                                    React.createElement(Select, { fullWidth: true, "data-qa-id": "projectInfoProjectStageInput", id: "projectInfoProjectStageInput", value: state.newProject ? state.newProject.projectStage : (_f = state.existingProject) === null || _f === void 0 ? void 0 : _f.projectStage, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => {
                                            var _a, _b;
                                            updateField('projectStage', value);
                                            updateField('projectStageValue', (_b = (_a = projectStages.find(ps => ps.id === value)) === null || _a === void 0 ? void 0 : _a.code) !== null && _b !== void 0 ? _b : '');
                                        }, variant: "outlined" },
                                        React.createElement(MenuItem, { key: "null", value: "" }, "\u00A0"),
                                        projectStages.map(({ id, code }) => (React.createElement(MenuItem, { key: id, value: id }, code)))))),
                            React.createElement(Grid, { container: true, spacing: 3 },
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "projectInfoProjectCityInput", "data-qa-id": "projectInfoProjectCityInputLabel" }, "Project City"),
                                    React.createElement(TextField, { id: "projectInfoProjectCityInput", "data-qa-id": "projectInfoProjectCityInput", fullWidth: true, variant: "outlined", value: state.newProject ? state.newProject.projectCity : (_g = state.existingProject) === null || _g === void 0 ? void 0 : _g.projectCity, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => updateField('projectCity', value) })),
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "projectInfoCountyStateInput", "data-qa-id": "projectInfoCountyStateInputLabel" }, "Project County State"),
                                    React.createElement(Select, { fullWidth: true, "data-qa-id": "projectInfoCountyStateInput", id: "projectInfoCountyStateInput", value: state.newProject ? state.newProject.projectCountyState : (_h = state.existingProject) === null || _h === void 0 ? void 0 : _h.projectCountyState, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => {
                                            var _a, _b;
                                            updateField('projectCountyState', value);
                                            updateField('projectCountyStateValue', (_b = (_a = projectCountyStates.find(pcs => pcs.id === value)) === null || _a === void 0 ? void 0 : _a.county) !== null && _b !== void 0 ? _b : '');
                                        }, variant: "outlined" },
                                        React.createElement(MenuItem, { key: "null", value: "" }, "\u00A0"),
                                        projectCountyStates.map(({ id, county }) => (React.createElement(MenuItem, { key: id, value: id }, county))))),
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "projectInfoProjectPostCodeInput", "data-qa-id": "projectInfoProjectPostCodeLabel" }, "Project Postcode"),
                                    React.createElement(TextField, { id: "projectInfoProjectPostCodeInput", "data-qa-id": "projectInfoProjectPostCodeInput", fullWidth: true, variant: "outlined", value: state.newProject ? state.newProject.projectPostCode : (_j = state.existingProject) === null || _j === void 0 ? void 0 : _j.projectPostCode, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => updateField('projectPostCode', value) }))),
                            React.createElement(Grid, { container: true, spacing: 3 },
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "projectInfoProjectUsageInput", "data-qa-id": "projectInfoProjectUsageInputLabel" }, "Project Usage"),
                                    React.createElement(Select, { fullWidth: true, "data-qa-id": "projectInfoProjectUsageInput", id: "projectInfoProjectUsageInput", value: state.newProject ? state.newProject.projectUsage : (_k = state.existingProject) === null || _k === void 0 ? void 0 : _k.projectUsage, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => {
                                            var _a, _b;
                                            updateField('projectUsage', value);
                                            updateField('projectUsageValue', (_b = (_a = projectUsages.find(pu => pu.id === value)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '');
                                        }, variant: "outlined" },
                                        React.createElement(MenuItem, { key: "null", value: "" }, "\u00A0"),
                                        projectUsages.map(({ id, name }) => (React.createElement(MenuItem, { key: id, value: id }, name))))),
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "projectInfoProjectTypeInput", "data-qa-id": "projectInfoProjectTypeInputLabel" }, "Project Type"),
                                    React.createElement(Select, { fullWidth: true, "data-qa-id": "projectInfoProjectTypeInput", id: "projectInfoProjectTypeInput", value: state.newProject ? state.newProject.type : (_l = state.existingProject) === null || _l === void 0 ? void 0 : _l.type, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => updateField('type', value), variant: "outlined" },
                                        React.createElement(MenuItem, { key: "null", value: "" }, "\u00A0"),
                                        ['New Build', 'Extension', 'Refurbishment'].map(projectType => (React.createElement(MenuItem, { key: projectType, value: projectType }, projectType))))),
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "projectInfoRegionInput", "data-qa-id": "projectInfoRegionInputLabel" }, "Region"),
                                    React.createElement(Select, { fullWidth: true, "data-qa-id": "projectInfoRegionInput", id: "projectInfoRegionInput", value: state.newProject ? state.newProject.region : (_m = state.existingProject) === null || _m === void 0 ? void 0 : _m.region, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => {
                                            var _a, _b;
                                            updateField('region', value);
                                            updateField('regionValue', (_b = (_a = projectRegions.find(pr => pr.id === value)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '');
                                        }, variant: "outlined" },
                                        React.createElement(MenuItem, { key: "null", value: "" }, "\u00A0"),
                                        projectRegions.map(({ id, name }) => (React.createElement(MenuItem, { key: id, value: id }, name)))))),
                            React.createElement(Grid, { container: true, spacing: 3 },
                                React.createElement(Grid, { item: true, xs: 4 },
                                    React.createElement(InputLabel, { htmlFor: "projectInfoBuildingHeightInput", "data-qa-id": "projectInfoBuildingHeightInputLabel" }, "Building Height"),
                                    React.createElement(Grid, { container: true, alignItems: "center", spacing: 1 },
                                        React.createElement(Grid, { item: true, xs: 11 },
                                            React.createElement(TextField, { id: "projectInfoBuildingHeightInput", "data-qa-id": "projectInfoBuildingHeightInput", fullWidth: true, variant: "outlined", value: state.newProject ? state.newProject.buildingHeight : (_o = state.existingProject) === null || _o === void 0 ? void 0 : _o.buildingHeight, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => updateField('buildingHeight', value) })),
                                        React.createElement(Grid, { item: true, xs: 1 },
                                            React.createElement("div", { className: modalStyles.inputUnit, "data-qa-id": "projectInfoBuildingHeightInputUnit" }, "m")))),
                                React.createElement(Grid, { item: true, xs: 8 },
                                    React.createElement(InputLabel, { "data-qa-id": "projectInfoProjectSizeInputLabel" }, "Project Size"),
                                    React.createElement("div", { className: modalStyles.relative },
                                        React.createElement(ButtonGroup, { size: "large", variant: "contained", className: styles.multiButtonContainer, "data-qa-id": "projectInfoProjectSizeButtons" }, ['Small', 'Large (not in CRM)', 'Large (in CRM)'].map((size, index) => {
                                            var _a;
                                            return (React.createElement(Button, { key: size, disableRipple: true, disableFocusRipple: true, disableTouchRipple: true, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, className: (state.newProject
                                                    ? state.newProject.size === size
                                                    : ((_a = state.existingProject) === null || _a === void 0 ? void 0 : _a.size) === size)
                                                    ? styles.multiButtonSelected
                                                    : '', onClick: () => updateField('size', size), "data-qa-id": `projectInfoProjectSizeButton${index}` }, size));
                                        }))))),
                            React.createElement(Grid, { container: true, spacing: 3 },
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(InputLabel, { htmlFor: "projectInfoSupportingInformationInput", "data-qa-id": "projectInfoSupportingInformationInputLabel" }, "Project Supporting Information"),
                                    React.createElement(TextField, { id: "projectInfoSupportingInformationInput", "data-qa-id": "projectInfoSupportingInformationInput", fullWidth: true, variant: "outlined", value: state.newProject ? state.newProject.supportingInfo : (_p = state.existingProject) === null || _p === void 0 ? void 0 : _p.supportingInfo, disabled: calculation === null || calculation === void 0 ? void 0 : calculation.locked, onChange: ({ target: { value } }) => updateField('supportingInfo', value), inputProps: {
                                            maxLength: 255,
                                        } }))),
                            React.createElement(Grid, { container: true, spacing: 3 },
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(InputLabel, { "data-qa-id": "projectInfoEnquiryTypeInputLabel" }, "Source of Enquiry"),
                                    React.createElement("div", { className: modalStyles.relative },
                                        React.createElement(ButtonGroup, { size: "large", variant: "contained", className: styles.multiButtonContainer, "data-qa-id": "projectInfoEnquiryType" }, ['Telephone', 'Email', 'Other', 'Website'].map((enquiryType, index) => {
                                            var _a;
                                            return (React.createElement(Button, { key: enquiryType, disableRipple: true, disableFocusRipple: true, disableTouchRipple: true, className: (state.newProject
                                                    ? state.newProject.enquiryType === enquiryType
                                                    : ((_a = state.existingProject) === null || _a === void 0 ? void 0 : _a.enquiryType) === enquiryType)
                                                    ? styles.multiButtonSelected
                                                    : '', onClick: () => updateField('enquiryType', enquiryType), "data-qa-id": `projectInfoProjectEnquiryTypeButton${index}` }, enquiryType));
                                        }))))))))),
            React.createElement("div", { className: modalStyles.modalActions },
                React.createElement(DialogActions, null,
                    React.createElement(Grid, { container: true, spacing: 2, justify: "flex-end" },
                        React.createElement(Grid, { container: true, item: true, xs: 2, justify: "flex-end" },
                            React.createElement(Button, { "data-qa-id": "projectInfoCloseButton", onClick: onClose, variant: "outlined" }, "Cancel")),
                        React.createElement(Grid, { container: true, item: true, xs: 3, justify: "flex-end" },
                            React.createElement(Button, { type: "button", "data-qa-id": "projectInfoAssociateAllButton", fullWidth: true, onClick: (event) => handleSubmit(event, true), color: "primary", variant: "outlined", disabled: disableButtons }, "Associate to all")),
                        React.createElement(Grid, { item: true, xs: 3 },
                            React.createElement(Button, { type: "submit", "data-qa-id": "projectInfoSubmitButton", fullWidth: true, color: "primary", variant: "contained", disabled: disableButtons }, "Associate to current"))))))));
}
